<template>
  <page-view ref="pageView" :title="title" left-arrow>
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{}" />
    </template>

    <van-form :show-error="true" :show-error-message="false" :scroll-to-error="true" :validate-first="true"
      @submit="onSubmit" @failed="onFailed">
      <van-cell-group class="mt-10">
        <ywfl-select
          required
          ywfl="6"
          v-model="ywlxArr" 
          name="ywlxArr" 
          label="业务类型" 
          placeholder="请选择业务类型"
          title="选择业务类型"
          :rules="[{ required: true, message: '请选择业务类型' }]"
        />
        <van-field required v-model="form.bzsm" name="bzsm" label="变更内容" type="textarea" placeholder="描述变更需求"
          :rules="[{ required: true, message: '请输入变更需求' }]" />
      </van-cell-group>

      <van-cell-group class="mt-10">
        <zzjg-select
            v-model="form.jgbmList"
            :sjzzbm="'1R'"
            :required="true"
            @confirm="zzjgConfirm"/>
        <hsdy-select
            ref="hsdySelect"
            v-model="form.dybmList"
            :jgbm="form.jgbm"
            :required="true"
            @confirm="hsdyConfirm"/>
        <van-field v-model="form.yhbh" name="yhbh" label="户号" :disabled="isBdzdReadonly('yhbh')" />
        <van-field v-if="isShowBdzd('yyhmc')" type="text" clearable v-model="form.yyhmc" name="yyhmc" label="原用户名称" placeholder="输入原用户名称" :disabled="isBdzdReadonly('yyhmc')"/>
        <van-field v-model="form.yhmc" name="yhmc" label="用户名称" clearable :disabled="isBdzdReadonly('yhmc')" />
        <van-field v-if="isShowBdzd('yyhdz')" type="text" clearable v-model="form.yyhdz" name="yyhdz" label="原用电地址" placeholder="输入原用电地址" :disabled="isBdzdReadonly('yyhdz')"  />
        <van-field v-model="form.yhdz" name="yhdz" label="用电地址" clearable placeholder=""
          :rules="[{ required: true, message: '请输入用电地址' }]"  :disabled="isBdzdReadonly('yhdz')"/>
        <vant-select v-if="isShowBdzd('dqzt')"  :disabled="isBdzdReadonly('dqzt')"  v-model="form.dqzt" name="dqzt" label="当前状态" :columns="zfdmOptions['DQZT']"/>
      </van-cell-group>

      <van-cell-group class="mt-10">
        <van-field v-model="form.zjbh" type="text" clearable name="zjbh" label="身份证号" placeholder="输入身份证号" :disabled="isBdzdReadonly('zjbh')"
          :rules="[{ required: true, message: '请输入身份证号' }]" />
        <van-field v-model="form.lxr" type="text" clearable name="lxr" label="联系人" placeholder="" :disabled="isBdzdReadonly('lxr')"
          :rules="[{ required: true, message: '请输入联系人' }]" />
        <van-field v-model="form.lxsj" type="text" clearable name="lxsj" label="联系手机" placeholder="输入联系手机" :disabled="isBdzdReadonly('lxsj')"
          :rules="[{ required: true, message: '请输入联系手机' }]" />
        <van-field v-model="form.lxdh" type="text" clearable name="lxdh" label="联系电话" placeholder="输入联系电话" :disabled="isBdzdReadonly('lxdh')"/>
      </van-cell-group>

      <van-cell-group class="mt-10">
        <ydlb-select v-if="isShowBdzd('yydlb')"  :disabled="isBdzdReadonly('yydlb')"  :jgbm="form.jgbm" v-model="form.yydlb"  name="yydlb"  label="原用电类别"  placeholder="请选择原用电类别" title="选择用电类别" :rules="[{ required: true, message: '请选择用电类别' }]"/>
        <ydlb-select ref="ydlbSelect" v-if="isShowBdzd('ydlb')"   :disabled="isBdzdReadonly('ydlb')"   :jgbm="form.jgbm" required v-model="form.ydlb"  name="ydlb"  label="用电类别"  placeholder="请选择用电类别" title="选择用电类别" :rules="[{ required: true, message: '请选择用电类别' }]"/>
        <vant-select v-if="isShowBdzd('ygddy')"  :disabled="isBdzdReadonly('ygddy')"  v-model="form.ygddy" name="gddy" label="原供电电压" placeholder="请选择原供电电压" :columns="zfdmOptions['DYDJ']"/>
        <vant-select v-if="isShowBdzd('gddy')"   :disabled="isBdzdReadonly('gddy')"   v-model="form.gddy" name="gddy" label="供电电压" placeholder="请选择供电电压" :columns="zfdmOptions['DYDJ']"/>
        <vant-select v-if="isShowBdzd('yjbflb')" :disabled="isBdzdReadonly('yjbflb')" v-model="form.yjbflb" name="yjbflb" label="原基本费类别" placeholder="请选择原基本费类别" :columns="zfdmOptions['JBFLB']"/>
        <vant-select v-if="isShowBdzd('jbflb')"  :disabled="isBdzdReadonly('jbflb')"  v-model="form.jbflb" name="jbflb" label="基本费类别" placeholder="请选择基本费类别" :columns="zfdmOptions['JBFLB']"/>

        <van-field v-if="isShowBdzd('zrl')"    :disabled="isBdzdReadonly('zrl')"     type="text" clearable v-model="form.zrl" name="zrl" label="总容量" placeholder="输入总容量" />
        <van-field v-if="isShowBdzd('yzrl')"   :disabled="isBdzdReadonly('yzrl')"    type="text" clearable v-model="form.yzrl" name="yzrl" label="原装容量" placeholder="输入原装容量" />
        <van-field v-if="isShowBdzd('zjrl')"   :disabled="isBdzdReadonly('zjrl')"    type="text" clearable v-model="form.zjrl" name="zjrl" label="增加容量" placeholder="输入增加容量" />
        <van-field v-if="isShowBdzd('jsrl')"   :disabled="isBdzdReadonly('jsrl')"    type="text" clearable v-model="form.jsrl" name="jsrl" label="减少容量" placeholder="输入减少容量" />
        
        <van-field v-if="isShowBdzd('ztrl')"   :disabled="isBdzdReadonly('ztrl')"    type="text" clearable v-model="form.ztrl" name="ztrl" label="暂停容量" placeholder="输入暂停容量"/>
          <van-field v-if="isShowBdzd('hfrl')"   :disabled="isBdzdReadonly('hfrl')"    type="text" clearable v-model="form.hfrl" name="hfrl" label="恢复容量" placeholder="输入恢复容量" />
        <vant-datetime-select v-if="isShowBdzd('ztrq')"   :disabled="isBdzdReadonly('ztrq')"   clearable v-model="form.ztrq" name="ztrq" label="暂停时间" placeholder="选择暂停时间"></vant-datetime-select>
        <vant-datetime-select v-if="isShowBdzd('jhhfrq')" :disabled="isBdzdReadonly('jhhfrq')" clearable v-model="form.jhhfrq" name="jhhfrq" label="计划恢复时间" placeholder="选择计划恢复时间"></vant-datetime-select>
        <vant-datetime-select v-if="isShowBdzd('sjhfrq')" :disabled="isBdzdReadonly('sjhfrq')" clearable v-model="form.sjhfrq" name="sjhfrq" label="实际恢复时间" placeholder="选择实际恢复时间"></vant-datetime-select>
      </van-cell-group>

      <van-cell-group class="mt-10">
        <van-cell title="附件" class="van-cell--gzdfj">
          <gzd-fj-uploader ref="fjUploader" :gzdxx="gzdxx" :yhbh="form.yhbh" :gnid="gnid"/>
        </van-cell>
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">提 交</van-button>
      </div>
    </van-form>
  </page-view>
</template>

<script>
  import { parseTime } from '@/utils';
  import {
    DropdownMenu,
    DropdownItem
  } from 'vant';
  import {
    mapGetters
  } from 'vuex'
  import Loading from '@/components/LoadingLayout/loading.vue';
  import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
  import YhbhSelect from '@/components/YhbhSelect/index.vue';
  import YwflSelect from '@/components/YwflSelect';
  import YdlbSelect from '@/components/YdlbSelect';
  import GzdFjUploader from '@/components/GzdFjUploader';
  
  import VantSelect from '@/components/VantSelect/index.vue';
  import VantDatetimeSelect from '@/components/VantDatetimeSelect/index.vue';

  import {
    zfdmManageService_getZfdmByDmzl
  } from '@/api/zfdm'
  import {yhJbxx_fetchOneYhJbxxByYhbh} from '@/api/zhcx';
  import { gzdxxService_startTask , gzdxxService_GetQuXianListByGnid } from '@/api/psdmsgdgl/service/GzdxxServiceAPI';
  import {
    ykService_ykbgslCheck,
    ykService_ykbgslFunction,
  } from '@/api/psdmsykgl/service/YkServiceAPI'  //业扩变更受理API
  import { yhYwJbxx_selectiveUpdate } from '@/api/psdmsykgl/dawhschema/YhYwJbxxAPI.js';
  import ZzjgSelect from '@/components/zzjg-select/index.vue';
  import HsdySelect from '@/components/hsdy-select/index.vue';
  export default {
    name: 'YhzdQuery',
    components: {
      'van-dropdown-menu': DropdownMenu,
      'van-dropdown-item': DropdownItem,
      Loading,
      YhbhEmpty,
      YhbhSelect,
      YwflSelect,
      YdlbSelect,
      GzdFjUploader,
      VantSelect,
      VantDatetimeSelect,
      ZzjgSelect,
      HsdySelect
    },
    data() {
      return {
        title: '用电变更',
        gnid: '1027', // 业扩变更受理
        gzdxx: {
          qdrq: null,
          ywbh: null
        },
        ywlxArr: [],
        form: {
          yhbh: '',
          yhmc: '',
          yhdz: '',
          lxr: '',
          lxsj: '',
          bzsm: '',
          jgbm:this.$store.state.user.jgbm,
          dybm:this.$store.state.user.dybm,
          jgbmList:[],
          dybmList:[],
        },

        slFlag: 0, 
        zfdmOptions: {
          'DQZT': [],
          'DYDJ': [],
          'JBFLB': [],
        }
      }
    },
    computed: {
      ...mapGetters(['jgbm','dybm','czyh','dlzh']),
    },
    methods: {
      yhbhChangeHandle(yhxx) {
        this.form.yhbh = yhxx.yhbh;
        this.fetchYhJbxx();
      },
      fetchYhJbxx() {
        this.$toast.loading('加载中...')
        yhJbxx_fetchOneYhJbxxByYhbh(this.form.yhbh).then(res => {
          this.form = res.content;
          this.form.yyhmc = res.content.yhmc;
          this.form.yyhdz = res.content.yhdz;
          this.form.yydlb = res.content.ydlb;
          this.form.ygddy = res.content.gddy;
          this.form.yjbflb = res.content.jbflb;
        }).finally(() => {
          this.$toast.clear();
        });
      },
      onSubmit(values) {
        console.log('submit', values, this.form);
        this.createGzd();
      },
      onFailed({
        values,
        errors
      }) {
        console.log('failed:, Errors:', errors)
        this.$notify({
          type: 'danger',
          message: errors[0].message
        });
      },
      // 检查是否有在途工单
      beforeGzdCreate() {
        
        return new Promise((resolve, reject) => {
          if (this.slFlag == 1) {
            resolve();
          } else {
            let [ywfl, ywlb, ywzl] = this.ywlxArr;
            ykService_ykbgslCheck(this.form.yhbh,this.form.jgbm,ywlb,ywzl).then(res => {
              console.log(res);
              if (res.content.status && res.content.status == 400) {
                reject(new Error(res.content.message));
              } else {
                resolve();
              }
            }).catch(err => {
              reject(new Error("受理前校验失败："+err.message));
            });
          }
        });
      },
      // 新建工单
      createGzd() {
        this.beforeGzdCreate().then(() => {

          if (this.gzdxx && this.gzdxx.ywbh) {
            this.handleYkbgSL();
            return;
          }
          let [ywfl, ywlb, ywzl] = this.ywlxArr;
          let gzdsm = "微信-用电变更"
          let bzsm = this.form.bzsm;
          let params = {
            ywfl,
            ywlb,
            ywzl,
            jgbm: this.form.jgbm,
            dybm: this.form.dybm || this.$conf.DEFAULT_DYBM,
            gnid: this.gnid,
            gzdsm: gzdsm,
            jypz: bzsm,
  
            sdrid: this.czyh,
            czry: this.czyh,
            cjry: this.czyh
          };
          
          let loading = this.$toast.loading({
            forbidClick: true,
            message: "受理中...",
            duration: 0
          });
          gzdxxService_GetQuXianListByGnid(this.form.jgbm, this.gnid).then(response => {
            gzdxxService_startTask(params, {
              user: this.czyh,
              roleList: response.content
            }).then(res => {
              this.gzdxx = res.content.data;
              loading.clear();
              // 业扩变更受理
              this.handleYkbgSL();
            }).catch(err => {
              loading.clear();
              this.$toast.fail(err.message);
            });
          }).catch(err => {
            loading.clear();
            this.$toast.fail(err.message);
          });
        }).catch(err => {
          this.$dialog({
            type: 'warning',
            title: '提示',
            message: err.message,
          });
        });
      },
      // 业扩变更受理
      handleYkbgSL() {
        if (this.slFlag == 1) {
          this.updateYhYwJbxx();
          return;
        }
        let loading = this.$toast.loading({
          forbidClick: true,
          message: "受理中...",
          duration: 0
        });
        let {qdrq, ywbh} = this.gzdxx;
        qdrq = parseTime(new Date(qdrq));
        // qdrq,ywbh,yhbh,yyqd,dlzh
        ykService_ykbgslFunction( qdrq,ywbh, this.form.yhbh, "1" ,this.dlzh).then(res => {
          console.log(res);
          if(res.content.status==200){
            loading.clear();
            // 受理成功
            this.slFlag = 1;
            this.updateYhYwJbxx();
          } else {
            throw new Error(res.content.message)
          }
        }).catch(err => {
          loading.clear();
          this.$toast("受理失败："+ err.message);
        });
      },

      // 更新用户业务基本信息
      updateYhYwJbxx() {
        let loading = this.$toast.loading({
          forbidClick: true,
          message: "受理中...",
          duration: 0
        });
        setTimeout(() => {
          this.$refs['fjUploader'].uploadFileList();
        }, 500);
        const yhJbxx = Object.assign({}, this.form);
        yhJbxx.qdrq = this.gzdxx.qdrq;
        yhJbxx.ywbh = this.gzdxx.ywbh;
        // 不要更新总容量、原装容量！！！ 后台会自动处理
        delete yhJbxx.yzrl;
        delete yhJbxx.zrl;
        yhYwJbxx_selectiveUpdate(yhJbxx).then(res => {
          console.log(res);
          loading.clear();
        }).catch(err => {
          loading.clear();
          this.$toast("受理失败："+ err.message);
        });
      },

      isShowBdzd(field) {
        let ywzl = this.ywlxArr && this.ywlxArr[2]+'' ? this.ywlxArr[2]: '';

        let filedArr = [];
        switch (ywzl) {
          case '601': // 基本信息变更
            filedArr = ['yhbh','yhmc','yhdz','zjbh','lxr','lxsj','lxdh'];
            break;
          case '611': // 增容
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','yzrl','zjrl','zrl'];
            break;
          case '612': // 减容
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','yzrl','jsrl','zrl'];
            break;
          case '621': // 更名
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','yyhmc'];
            break;
          case '622': // 过户
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','yyhmc'];
            break;
          case '631': // 暂停
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','ztrl','ztrq','jhhfrq'];
            break;
          case '641': // 暂停恢复
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','ztrl','hfrl','ztrq','jhhfrq','sjhfrq'];
            break;
          case '651': // 改类
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','ydlb','yydlb','gddy'];
            break;
          case '652': // 改压
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','ydlb','gddy','ygddy'];
            break;
          case '681': // 移换表
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj', 'yyhdz'];
            break;
          case '691': // 临转永
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','lsbz'];
            break;
          case '6a1': // 临时减容
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','ztrl','ztrq','jhhfrq'];
            break;
          case '6b1': // 减容恢复
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','ztrl','hfrl','ztrq','jhhfrq','sjhfrq'];
            break;
          case '6c1': // 基本费方式变更
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','jbflb','yjbflb'];
            break;
          case '711': // 销户
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','dqzt'];
            break;
          default:
            break;
        }

        return filedArr.indexOf(field) != -1;
      },

      isBdzdReadonly(field) {
        let ywzl = this.ywlxArr && this.ywlxArr[2]+'' ? this.ywlxArr[2]: '';

        let filedArr = [];
        switch (ywzl) {
          case '601': // 基本信息变更
            filedArr = ['yhbh','yhmc','yhdz'];
            break;
          case '611': // 增容
            filedArr = ['yhbh','yhmc','yhdz','yzrl','zrl'];
            break;
          case '612': // 减容
            filedArr = [ 'yhbh','yhmc','yhdz','yzrl','zrl'];
            break;
          case '621': // 更名
            filedArr = ['yhbh','yhdz','yyhmc'];
            break;
          case '622': // 过户
            filedArr = ['yhbh','yhdz','yyhmc'];
            break;
          case '631': // 暂停
            filedArr = ['yhbh','yhmc','yhdz'];
            break;
          case '641': // 暂停恢复
            filedArr = ['yhbh','yhmc','yhdz','ztrl','ztrq','jhhfrq'];
            break;
          case '651': // 改类
            filedArr = ['yhbh','yhmc','yhdz','yydlb','gddy'];
            break;
          case '652': // 改压
            filedArr = ['yhbh','yhmc','yhdz','ydlb','ygddy'];
            break;
          case '681': // 移换表
            filedArr = ['yhbh','yhmc','yyhdz'];
            break;
          case '691': // 临转永
            filedArr = ['yhbh','yhmc','yhdz','lsbz'];
            break;
          case '6a1': // 临时减容
            filedArr = ['yhbh','yhmc','yhdz'];
            break;
          case '6b1': // 减容恢复
            filedArr = ['yhbh','yhmc','yhdz','ztrl','ztrq','jhhfrq'];
            break;
          case '6c1': // 基本费方式变更
            filedArr = ['yhbh','yhmc','yhdz','yjbflb'];
            break;
          case '711': // 销户
            filedArr = ['yhbh','yhmc','yhdz','lxr','lxsj','dqzt'];
            break;
          default:
            break;
        }

        return filedArr.indexOf(field) > -1;
      },
      zzjgConfirm(value,options){
        if(value && value.length){
          this.form.jgbm = value[value.length-1];
          this.form.dybm = '';
          this.form.dybmList = [];
          this.$nextTick(()=>{
            this.$refs.hsdySelect.getHsdyList();
            if(this.$refs.ydlbSelect){
              this.$refs.ydlbSelect.fetchYWFLData();
            }
          })
        }
      },
      hsdyConfirm(value,options){
        if(value && value.length){
          this.form.dybm = value[value.length-1];
        }
      },
    },
    mounted() {
      this.ywlxArr = [];
      this.$store.dispatch('getYhbhList').then(list => {
        if (list.length) {
          this.form.yhbh = this.$route.query.yhbh ? this.$route.query.yhbh : list[0].yhbh;
          this.fetchYhJbxx();
        }
      });
      
      for(let key in this.zfdmOptions) {
        zfdmManageService_getZfdmByDmzl(key).then(response => {
          this.zfdmOptions[key] = response.content.map(item => {
            item.label = item.dmnr;
            item.value = item.dmbh;
            return item;
          });
        });
      }
    },

    watch: {
      'ywlxArr': {
        handler(val) {
          const [ywfl, ywlb, ywzl] = val;
          if (['63','64','6a','6b'].indexOf(ywlb+'') > -1) {
            this.gnid = '1319'; // 用电变更申请
          } else {
            this.gnid = '1027'; // 用电变更受理
          }
        },
        immediate: true,
        deep: true
      }
    },
  }
</script>

<style lang="scss" scoped>
  .van-cell--gzdfj /deep/ {
    .van-cell__title {
      flex-basis: 6.2em;
      flex-grow: 0;
    }
  }
  .van-field--disabled /deep/ {
    .van-field__label,
    .van-cell__value
    {
      color: #8c8c8c;
    }
    .van-field__control:disabled {
      color: #8c8c8c;
      -webkit-text-fill-color: #8c8c8c;
    }
  }
</style>